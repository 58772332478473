import "./App.css";
import MainContent from "./components/MainContent";

function App() {
  return (
    <div className="App" style={{ height: window.innerHeight }}>
      <MainContent />
    </div>
  );
}

export default App;
